import * as THREE from 'three';
import { useThree } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';

let models = [
  {
    name: 'EXT',
    path: '/lynkco/model/LynkcoGNC_EXT.glb',
  },
  {
    name: 'INT',
    path: '/lynkco/model/LynkcoGNC_INT.glb',
  },
  {
    name: 'Tires',
    path: '/lynkco/model/LynkcoGNC_Tires.glb',
  },
  {
    name: 'LBDoor',
    path: '/lynkco/model/LynkcoGNC_LBDoor.glb',
  },
  {
    name: 'LFDoor',
    path: '/lynkco/model/LynkcoGNC_LFDoor.glb',
  },
  {
    name: 'RFDoor',
    path: '/lynkco/model/LynkcoGNC_RFDoor.glb',
  },
  {
    name: 'RBDoor',
    path: '/lynkco/model/LynkcoGNC_RBDoor.glb',
  },
];

//The Cubemap path
var r = '/cubemap/';
//The cubemap file urls
var urls = [r + 'posx.jpg', r + 'negx.jpg', r + 'posy.jpg', r + 'negy.jpg', r + 'posz.jpg', r + 'negz.jpg'];
//The Cubemap object
var mCubeMap;

//The texture loader
var mTextureLoader;

//The loader manager
var mManager;

//Load the environmet cubemap from file
mCubeMap = new THREE.CubeTextureLoader().load(urls);
mCubeMap.format = THREE.RGBAFormat;
mCubeMap.mapping = THREE.CubeReflectionMapping;

//Create a loader manager
mManager = new THREE.LoadingManager();

//Creat the texture loader
mTextureLoader = new THREE.TextureLoader(mManager);

const testMaterial = new THREE.MeshStandardMaterial({
  color: '#ff0000',
});

let whiteLightMaterial = new THREE.MeshStandardMaterial({
  color: '#ffffff',
});

let redLightMaterial = new THREE.MeshStandardMaterial({
  color: '#ff0000',
});

let goldMaterial = new THREE.MeshStandardMaterial({
  color: '#D7AE50',
});

var Mt_WindScreens = new THREE.MeshStandardMaterial({
  color: 0x000000,
  roughness: 0.0,
  metalness: 0.0,
  envMap: mCubeMap,
  transparent: true,
  opacity: 0.7,
});

var dfCol_Body = webColorToHex('#000000');

var Mt_Body = new THREE.MeshStandardMaterial({
  name: 'Mt_Body',
  color: dfCol_Body,
  roughness: 0.0,
  metalness: 0.0,
  envMap: mCubeMap,
});

var Hub01_AO = LoadTextureCorrected(mTextureLoader, '/lynkco/texture/Hub01_AO.jpg');

var Hub_N = LoadTextureCorrected(mTextureLoader, '/lynkco/texture/Hub-N.jpg');

var Mt_Tyres_Hub = new THREE.MeshStandardMaterial({
  color: 0x000000,
  roughness: 1,
  metalness: 1,
  envMap: mCubeMap,
  map: Hub01_AO,
  normalMap: Hub_N,
});

//Function to convert webcolor to hex color
function webColorToHex(color) {
  return parseInt(color.replace('#', '0x'));
}

/*eslint-disable*/
//Function to load texture corrected
function LoadTextureCorrected(_loader, _path) {
  //Load the texture
  var texture = _loader.load(_path);
  //Set repeat wrapping
  texture.wrapT = texture.wrapS = THREE.RepeatWrapping;
  //Flip texture vertically
  texture.repeat.y = -1;
  //Return the corrected texture
  return texture;
}

function Lynkco() {
  const { scene } = useThree();

  const { scene: ext } = useGLTF(models[0].path);
  ext.scale.set(0.1, 0.1, 0.1);
  ext.rotation.set(0, -Math.PI / 2, 0);
  ext.traverse(child => {
    if (child.isMesh) {
      child.material = Mt_Body;
      if (child.name === 'EXT_20') {
        child.material = Mt_WindScreens;
      }
      if (child.name === 'EXT_11' || child.name === 'EXT_16') {
        child.material = whiteLightMaterial;
      }
      if (child.name === 'EXT_13' || child.name === 'EXT_08') {
        child.material = redLightMaterial;
      }
      if (child.name === 'EXT_05' || child.name === 'EXT_17') {
        child.material = redLightMaterial;
      }
      if (child.name === 'EXT_09' || child.name === 'EXT_22' || child.name === 'EXT_17') {
        child.material = goldMaterial;
      }
    }
  });
  const { scene: int } = useGLTF(models[1].path);
  int.scale.set(0.1, 0.1, 0.1);
  int.rotation.set(0, -Math.PI / 2, 0);
  int.traverse(child => {
    if (child.isMesh) {
      child.material = Mt_Body;
    }
  });
  const { scene: tires } = useGLTF(models[2].path);
  tires.scale.set(0.1, 0.1, 0.1);
  tires.rotation.set(0, -Math.PI / 2, 0);
  tires.traverse(child => {
    if (child.isMesh) {
      child.material = Mt_Body;
      if (child.name.includes('10')) {
        child.material = Mt_Tyres_Hub;
      }
    }
  });
  const { scene: lbdoor } = useGLTF(models[3].path);
  lbdoor.scale.set(0.1, 0.1, 0.1);
  lbdoor.rotation.set(0, -Math.PI / 2, 0);
  lbdoor.traverse(child => {
    if (child.isMesh) {
      child.material = Mt_Body;
      if (child.name.includes('09')) {
        child.material = Mt_WindScreens;
      }
    }
  });
  const { scene: lfdoor } = useGLTF(models[4].path);
  lfdoor.scale.set(0.1, 0.1, 0.1);
  lfdoor.rotation.set(0, -Math.PI / 2, 0);
  lfdoor.traverse(child => {
    if (child.isMesh) {
      child.material = Mt_Body;
      if (child.name.includes('09')) {
        child.material = Mt_WindScreens;
      }
    }
  });
  const { scene: rfdoor } = useGLTF(models[5].path);
  rfdoor.scale.set(0.1, 0.1, 0.1);
  rfdoor.rotation.set(0, -Math.PI / 2, 0);
  rfdoor.traverse(child => {
    if (child.isMesh) {
      child.material = Mt_Body;
      if (child.name.includes('09')) {
        child.material = Mt_WindScreens;
      }
    }
  });
  const { scene: rbdoor } = useGLTF(models[6].path);
  rbdoor.scale.set(0.1, 0.1, 0.1);
  rbdoor.rotation.set(0, -Math.PI / 2, 0);
  rbdoor.traverse(child => {
    if (child.isMesh) {
      child.material = Mt_Body;
      if (child.name.includes('09')) {
        child.material = Mt_WindScreens;
      }
    }
  });

  scene.add(ext);
  scene.add(int);
  scene.add(tires);
  scene.add(lbdoor);
  scene.add(lfdoor);
  scene.add(rfdoor);
  scene.add(rbdoor);
}

export default Lynkco;
