import { Canvas } from '@react-three/fiber';
import Experience from './Experience';
import { Leva } from 'leva';
import { Suspense } from 'react';
import { useProgress } from '@react-three/drei';

function App() {
  const { progress } = useProgress();

  return (
    <>
      <Suspense
        fallback={
          <div
            style={{
              width: '100vw',
              height: '100vh',
              backgroundColor: 'black',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '16px',
              fontFamily: '"Gill Sans", sans-serif',
              color: '#fff',
            }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#ffffff"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="lucide lucide-loader">
              <line x1="12" x2="12" y1="2" y2="6" />
              <line x1="12" x2="12" y1="18" y2="22" />
              <line x1="4.93" x2="7.76" y1="4.93" y2="7.76" />
              <line x1="16.24" x2="19.07" y1="16.24" y2="19.07" />
              <line x1="2" x2="6" y1="12" y2="12" />
              <line x1="18" x2="22" y1="12" y2="12" />
              <line x1="4.93" x2="7.76" y1="19.07" y2="16.24" />
              <line x1="16.24" x2="19.07" y1="7.76" y2="4.93" />
            </svg>
            奥利奥正在疯狂写 bug......{progress ? `${Math.floor(progress)}%` : ''}
          </div>
        }>
        <Canvas camera={{ position: [-1, 0.5, -1] }}>
          <Experience />
        </Canvas>
      </Suspense>

      {/* <Leva collapsed /> */}
    </>
  );
}

export default App;
