import { useRef } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { OrbitControls, ContactShadows } from '@react-three/drei';

import * as THREE from 'three';
import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper';
import { RectAreaLightUniformsLib } from 'three/examples/jsm/lights/RectAreaLightUniformsLib.js';
import { Ground } from './Ground';
import { Lamborghini } from './Car';
import { Effects } from './Effects';
import Lynkco from './lynkco';

function Experience() {
  const { scene } = useThree();

  RectAreaLightUniformsLib.init();

  const rectLight = new THREE.RectAreaLight(0xffffff, 0.6, 4, 10);
  rectLight.position.set(0, 3, 0);
  rectLight.rotation.set(-Math.PI / 2, 0, 0);
  scene.add(rectLight);
  scene.add(new RectAreaLightHelper(rectLight));

  return (
    <>
      <color attach="background" args={['#15151a']} />

      <Lynkco />

      <Ground />

      <Effects />

      <ContactShadows resolution={1024} frames={1} position={[0, 0, 0]} scale={15} blur={0.5} opacity={1} far={20} />

      <OrbitControls enablePan={false} maxPolarAngle={Math.PI * 0.45} minPolarAngle={Math.PI * 0.35} maxDistance={5} minDistance={3} />
    </>
  );
}

export default Experience;
